import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { featureFlag } from 'src/environments/environment';
import * as moment from 'moment';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';
import { ADFS_URL } from '../../http/api.constant';
import { Select, Store } from '@ngxs/store';
import {
  ClearRemainingAttempt,
  ClearState,
  Login,
} from 'src/app/store/auth/auth.actions';
import { versionInfo } from '../../../../version-info';
import { ReCaptcha2Component } from 'ngx-captcha/public_api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

declare let hljs: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  loginForm: FormGroup;
  loginAdfsEnable = featureFlag.login_adfs_enable;
  storage_space_status = featureFlag.storage_space_status;
  storage_subscription_status =
    featureFlag.storage_subscription_status;
  package_subscription_status =
    featureFlag.package_subscription_status;
  additional_vm_subscription_status =
    featureFlag.additional_vm_subscription_status;
  loading = false;
  submitted = false;
  error = '';
  forgotPasswordMode = false;
  forgotPasswordForm: FormGroup;
  forgotPasswordSuccess = false;
  email = '';
  currentTime: Date;
  currentDate = moment().format('DD MMMM YYYY');
  language = 'en';
  loginMode = true;
  adfsLoginMode = featureFlag.login_adfs_enable;
  textList: string;
  themeList: ThemeList;
  version = versionInfo.hash;
  isMobile;
  url = window.location.hostname;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public siteKey = '6LeoZ70dAAAAAP2ZkEanBnuBvtPsL0I97HVKHKGn';

  public aFormGroup: FormGroup;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'compact';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain = false;
  @ViewChild('captchaElem', { static: false })
  captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  @ViewChild('wrongPasswordAttempt', { static: false })
  wrongPasswordAttempt: ElementRef;

  @Select((state) => state.auth?.remaining_attempts)
  loginFailed$: Observable<number>;
  remainingAttempts: number;

  subscription: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private spinner: SpinnerService,
    private navbar: NavbarService,
    private alert: AlertService,
    private translate: TranslateService,
    private themeService: ThemeService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
  ) {
    this.language = this.translate.currentLang;
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(ClearState);
    this.checkCurrentTime();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
    this.navbar.setActiveSidebar(false);
    this.changeLanguage(this.language);

    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
    this.isMobile = this.themeService.isMobile();
    this.checkResponsive();
    this.subscribeLoginFailed();
  }

  ngAfterViewInit(): void {
    this.highlight();
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  checkCurrentTime(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  adfsLogin(): void {
    window.location.href = ADFS_URL.login + '?email=' + this.email;
  }

  onSubmit(): void {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
    }
    this.loading = true;
    this.store.dispatch(
      new Login({
        username: this.f.username.value,
        password: this.f.password.value,
      }),
    );
  }

  submitForgotPassword(): void {
    const subscription = this.authenticationService
      .forgotPassword({
        email: this.email,
        recaptcha_response: this.captchaResponse,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
      )
      .subscribe(
        () => {
          this.forgotPasswordSuccess = true;
          this.textList = 'LOGIN.EMAIL-RESET-TEXT';
        },
        (error) => {
          if (error?.error === 'IS_AD_USER') {
            this.alert.error(
              'this is adfs user. please contact adfs admin.',
            );
          } else if (error?.error?.detail) {
            this.alert.error(error.error.detail);
          } else {
            this.alert.error(error.error.email.join());
          }
        },
      );
    this.subscription.push(subscription);
  }

  toggleForgetPassword(): void {
    this.modalService.dismissAll();
    this.forgotPasswordMode = true;
    this.loginMode = false;
    this.textList = 'LOGIN.PASS-WORK-RESET-TEXT';
    this.forgotPasswordSuccess = false;
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  gotoLogin(): void {
    this.loginMode = true;
    this.forgotPasswordMode = false;
    this.email = '';
    this.textList = '';
    this.captchaSuccess = false;
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
    // this.checkValidate = true;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  handleError(): void {}

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  subscribeLoginFailed(): void {
    const subscription = this.loginFailed$.subscribe(
      (res: number) => {
        if (res) {
          if (res <= 3 && !this.modalService.hasOpenModals()) {
            this.remainingAttempts = res;
            this.modalService.open(this.wrongPasswordAttempt, {
              centered: true,
              backdrop: 'static',
            });
          } else if (res > 3) {
            this.alert.error(
              this.translate.instant(
                'LOGIN.INVALID-USERNAME-OR-PASSWORD',
              ),
            );
          }
        }
      },
    );
    this.subscription.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  closePopUp() {
    this.store.dispatch(new ClearRemainingAttempt());
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
