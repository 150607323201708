<div class="row mt-3"
    [style.row-gap]="'0.75rem'">
  <ng-content></ng-content>
  <ng-container *ngFor="let dropdown of dropdownList; let i = index">
    <div class="col-md-6 col-lg-4">
      <label>{{ (i > 0 ? 'MEMOS.SUB-DEPARTMENT': 'MEMOS.DEPARTMENT') | translate}}</label>
      <div class="d-flex no-gutters align-content-center align-center">
        <ng-select *ngIf="!refreshDropdown"
            class="col"
            [items]="dropdown"
            bindValue="id"
            [notFoundText]="'SELECT.No items found'|translate"
            bindLabel="{{translated === 'th' ? 'department_name' : 'department_name_en'}}"
            placeholder="{{'MEMOS.SELECT' | translate}}"
            [(ngModel)]="selectedDepartmentID[i] ? selectedDepartmentID[i]  : currentDepartmentId"
            (change)="selectedDepartment(i, $event)"
            (clear)="clearDepartment(i)">
        </ng-select>
        <div class="dept-dd-separator d-flex align-items-center"
            *ngIf="i < dropdownList.length - 1">
          /
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-md-6 col-lg-4 text-right-sm mt-sm-3 ml-auto d-flex justify-content-end align-items-end"
      [style.--c]="themeList?.header_table_color"
      [ngClass]="dropdownList.length > 1 ? 'mt-3':''">
    <button class="btn btn-clear ml-2 p-btn"
        *ngIf="showNextLevelButton"
        (click)="showNextLevelButtonClicked()">{{dropdownList.l}}
      {{'DEPARTMENT.SELECT-SUB-DEPARTMENT' | translate}}</button>
    <button class="btn btn-clear ml-2 p-btn"
        (click)="clearData()">{{clearLabel | translate}}</button>
  </div>
</div>