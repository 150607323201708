import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  PDFDocumentProxy,
  PdfViewerComponent as ParentPdfViewerComponent,
} from 'ng2-pdf-viewer';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { featureFlag } from '../../../../environments/environment';
import { StickyNoteComponent } from '../sticky-note/sticky-note.component';
import { StickyNoteService } from '../../service/sticky-note.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Store } from '@ngxs/store';
import { MemoService } from 'src/app/modules/memos/service/memo.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnChanges, OnDestroy {
  _featureFlag = featureFlag;
  @Input() pdfFile;
  @Input() showDownload = false;
  // fix static space for scrollbar because default style it makes pdf viewer width,height calculation problem
  // https://gitlab.c0d1um.io/products/e-memo/memo-bls-new/-/issues/246
  @Input() scrollbarGutterStable = false;
  @Output() downloaded = new EventEmitter<void>();
  @Output() clickPreview = new EventEmitter<void>();
  @Input() checkPage: string;
  @ViewChild('stickyNote') stickyNote: StickyNoteComponent;
  @Input() loginUserID: number = null;
  @Input() stickyNoteOwnerName = '';
  @Input() memoID: number = null;
  @Input() memo: any;
  @Input() memoNumber = '';
  @Input() canAddStickNote: boolean;
  showStickyNotePopupDownload = false;
  previewModal = false;

  themeList: ThemeList;
  currentDocumentPage: number;
  totalDocumentPage: number;
  isDocumentLoadingPage: boolean;
  httpHeaders;

  height;
  width;

  pdf;
  isLoading: boolean;

  @ViewChild(ParentPdfViewerComponent, { static: false })
  private pdfComponent: ParentPdfViewerComponent;
  @ViewChild('pdfView', { static: false })
  private pdfView: ElementRef;
  themeSubscription: Subscription[] = [];

  constructor(
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
    private stickyNoteService: StickyNoteService,
    private alert: AlertService,
    private store: Store,
    private memoService: MemoService,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    if (changes.pdfFile) {
      this.httpHeaders = this.authenticationService.httpHeader;
    }
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  onLoadPdfComplete(pdf: PDFDocumentProxy): void {
    this.currentDocumentPage = 1;
    this.totalDocumentPage = pdf.numPages;
    this.isDocumentLoadingPage = false;
    this.pdf = pdf;
  }

  get documentPages(): number[] {
    return Array(this.totalDocumentPage)
      .fill(1)
      .map((value, index) => index + 1);
  }

  changeDocumentPage(page: number): void {
    this.currentDocumentPage = +page;
  }

  changeDocumentPageBack(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage - 1 >= 1
    ) {
      this.currentDocumentPage--;
    }
  }

  changeDocumentPageNext(): void {
    if (
      !this.isDocumentLoadingPage &&
      this.currentDocumentPage + 1 <= this.totalDocumentPage
    ) {
      this.currentDocumentPage++;
    }
  }

  addNewStickyNote(): void {
    this.stickyNote?.addNewStickyNote();
  }

  showDownloadPDF(): void {
    this.showStickyNotePopupDownload =
      !this.showStickyNotePopupDownload;
  }

  async pageRendered(): Promise<void> {
    if (!this.pdf) {
      return;
    }
    const page = await this.pdf.getPage(this.currentDocumentPage);
    const originalPageView = page.getViewport({ scale: 1 });
    const scale =
      this.pdfView.nativeElement.clientWidth / originalPageView.width;
    const viewport = page.getViewport({ scale });
    this.height = viewport.height;
    this.width = viewport.width;
    this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  getHeight(): string {
    if (this.checkPage === 'pageAck') {
      return '100%';
    } else {
      return '';
    }
  }
  getPdfViewClass(): string {
    if (this.checkPage === 'approvalEmailPage') {
      return 'approval-email-page';
    } else {
      return 'other-page';
    }
  }

  getPdfViewerClass(): string {
    if (this.pdf) {
      return this.checkPage === 'approvalEmailPage'
        ? 'page-view-email bg-color'
        : 'page-view bg-color';
    } else {
      return '';
    }
  }

  getStickyNotes(searchString = ''): void {
    this.stickyNoteService
      .getStickyNotes(this.memoID, { search: searchString })
      .subscribe({
        next: (res) => {
          this.stickyNoteService.$stickyNotes.next(res);
        },
        error: (error) => {
          this.alert.error(
            typeof error.error === 'object'
              ? error.error.detail
              : error.error[0],
          );
        },
      });
  }

  previewPdf(): void {
    this.previewModal = true;
  }
}
